import React from "react";
import "../Assets/Css/Home.css";
import AnimatedText from "react-animated-text-content";
import "animate.css";

import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

export function Home() {
  return (
    <div id="Home">
      <AnimatedText
        type="words"
        animation={{
          x: "200px",
          y: "-20px",
          scale: 1.1,
          ease: "ease-in-out",
        }}
        animationType="blocks"
        interval={0.06}
        duration={0.8}
        tag="p"
        className="animated-paragraph"
        includeWhiteSpaces
        threshold={0.1}
        rootMargin="20%"
      >
        Hello
      </AnimatedText>
      <AnimatedText
        type="words"
        animation={{
          x: "200px",
          y: "-20px",
          scale: 1.1,
          ease: "ease-in-out",
        }}
        animationType="blocks"
        interval={0.06}
        duration={0.8}
        tag="p"
        className="animated-paragraph"
        includeWhiteSpaces
        threshold={0.1}
        rootMargin="20%"
      >
        I'm Ahmadjon Dadabayev
      </AnimatedText>
      <AnimatedText
        type="words"
        animation={{
          x: "200px",
          y: "-20px",
          scale: 1.1,
          ease: "ease-in-out",
        }}
        animationType="blocks"
        interval={0.06}
        duration={0.8}
        tag="p"
        className="animated-paragraph"
        includeWhiteSpaces
        threshold={0.1}
        rootMargin="20%"
      >
        Backend Developer
      </AnimatedText>

      <div id="my-link">
        <a
          className="animate__animated animate__backInUp"
          href="https://www.instagram.com/akhmadjon_dadabayev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon fontSize="large" />
        </a>

        <a
          className="animate__animated animate__backInUp"
          href="https://t.me/akhmadjon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TelegramIcon fontSize="large" />
        </a>
        <a
          className="animate__animated animate__backInUp"
          href="https://github.com/Akhmadjonuz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitHubIcon fontSize="large" />
        </a>
        {/* <a
          className="animate__animated animate__backInUp"
          href="https://www.youtube.com/c/OybekAbduljabbor"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon fontSize="large" />
        </a> */}
      </div>
    </div>
  );
}
