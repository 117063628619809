import React from "react";
import "../Assets/Css/Navbar.css";
import { NavLink } from "react-router-dom";
export function Navbar() {
  return (
    <nav id="nav">
      <NavLink to="/" className="nav-navigation">
        #Home
      </NavLink>
      <NavLink to="/about" className="nav-navigation">
        #About
      </NavLink>
      <NavLink to="/portfolio" className="nav-navigation">
        #Portfolio
      </NavLink>
    </nav>
  );
}
