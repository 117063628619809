import { useEffect, useState } from "react";
import "../Assets/Css/About.css";
import admin from "../Assets/Images/admin.jpg";
import LanguageIcon from "@mui/icons-material/Language";
import WorkIcon from "@mui/icons-material/Work";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ComputerIcon from "@mui/icons-material/Computer";
import CallIcon from "@mui/icons-material/Call";
import TelegramIcon from "@mui/icons-material/Telegram";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import axios from "axios";

import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

export function About() {
  const [value, setValue] = useState(4);
  const [like, setLike] = useState(0);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    axios("this api")
      .then((res) => {
        setLike(res.data.like);

        const max = res.data.like;
        const min = res.data.unlike;

        const prosents = Math.floor((max - min) / 281.1);

        setValue(prosents);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [update]);

  return (
    <div id="about-card">
      <div id="about-admin" className="animate__animated animate__fadeIn">
        <figure>
          <img src={admin} alt="admin" />
        </figure>
        <div id="about-admin-info">
          <p id="may-name">Ahmadjon Dadabayev</p>
          <p>
            <LocationCityIcon fontSize="medium" />
            <span>Namangan, Uzbekistan</span>
          </p>
          <p>
            <LanguageIcon fontSize="medium" />
            <span>Uzbek, Russian, Tajik</span>
          </p>

          <p>
            <WorkIcon fontSize="medium" />
            <span>Backend Developer</span>
          </p>
          <p>
            <ComputerIcon fontSize="medium" />
            <span>PHP Developer</span>
          </p>
          <p>
            <CallIcon fontSize="medium" />
            <a href="tel:+998902224311">+99 (890) 222 43 11</a>
          </p>
          <p>
            <AlternateEmailIcon fontSize="medium" />
            <a href="mailto:dadabayevuz@gmail.com">dadabayevuz@gmail.com</a>
          </p>
          <p>
            <TelegramIcon fontSize="medium" />
            <a
              href="https://t.me/akhmadjon"
              target="_blank"
              rel="noopener noreferrer"
            >
              @akhmadjon
            </a>
          </p>
          <p>
            <WorkspacesIcon fontSize="medium" />
            <span>{WorkExperience()}</span>
          </p>
          {/* <p>
            <ThumbUpIcon />
            <Rating name="read-only" value={value} readOnly />
            <span>{like} like</span>
          </p> */}

          {/* <div id="like-card">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                axios("this api", {
                  method: "POST",
                  data: {
                    like: false,
                    id: 1,
                  },
                });
                setUpdate(!update);
              }}
            >
              <ThumbDownAltIcon />
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                axios("this api", {
                  method: "POST",
                  data: {
                    like: true,
                    id: 1,
                  },
                });
                setUpdate(!update);
              }}
            >
              <ThumbUpIcon />
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

function WorkExperience() {
  const year = new Date().getFullYear() - 2018;
  const month = new Date().getMonth() + 1;

  return (
    <>
      <span>
        My work experience {year} year {month} month
      </span>
    </>
  );
}
