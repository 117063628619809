import "../Assets/Css/MyPortfolio.css";

import GitHubIcon from "@mui/icons-material/GitHub";
import LanguageIcon from "@mui/icons-material/Language";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";

export function MyPortfolio({ portfolio }) {
  function Update(item) {
    axios("https://api.dadabayev.uz/api/all", {
      method: "POST",
      data: {
        id: item.id,
        view: item.view + 1,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  return (
    <>
      {portfolio.map((item, index) => {
        return (
          <div id="MyPortfolio" key={index} onMouseUp={() => item}>
            <h1 id="num-portfolio">{index + 1}</h1>
            <img src={item.img} alt="test" />
            {/* <p id="view-portfolio">
              <VisibilityIcon color="primary" />
              <span> {item.view}</span>
            </p> */}
            <h1>{item.username}</h1>
            <div id="portfolio-info">
              <p>{item.name}</p>
              <div>
                <a
                  href={item.github_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHubIcon fontSize="large" />
                </a>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <LanguageIcon fontSize="large" />
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
