import React from "react";
import "../Assets/Css/Portfolio.css";

import { MyPortfolio } from "../Components/MyPortfolio";

export function Portfolio({ portfolio }) {
  return (
    <div id="portfolio">
      <div
        className="animate__animated animate__fadeIn"
        style={{ "--animate-duration": "2s" }}
      >
        <MyPortfolio portfolio={portfolio} />
      </div>
    </div>
  );
}
