import React, { useEffect, useState } from "react";
import "../Assets/Css/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";

import { Navbar } from "../Components/Navbar";
import { Home } from "../Pages/Home";
import { Portfolio } from "../Pages/Portfolio";
import { About } from "../Pages/About";

export function App() {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    axios("https://api.dadabayev.uz/api/all")
      .then((res) => {
        setPortfolio(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Router>
      <div id="App">
        <div>
          <Navbar />
        </div>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/portfolio"
              element={<Portfolio portfolio={portfolio} />}
            />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
